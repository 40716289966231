var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "carousel slide", style: { height: _vm.height || "auto" } },
    [
      _vm.indicators
        ? _c(
            "ol",
            { class: _vm.indicatorClasses },
            _vm._l(_vm.items.length, function(index, key) {
              return _c("li", {
                key: key,
                class: { active: _vm.activated === key },
                on: {
                  click: function($event) {
                    return _vm.setItem(key)
                  }
                }
              })
            }),
            0
          )
        : _vm._e(),
      _c("div", { staticClass: "carousel-inner" }, [_vm._t("default")], 2),
      _vm.arrows
        ? [
            _c(
              "a",
              {
                staticClass: "carousel-control-prev",
                on: { click: _vm.previousItem }
              },
              [
                _c("span", {
                  staticClass: "carousel-control-prev-icon",
                  attrs: { "aria-label": "Previous" }
                })
              ]
            ),
            _c(
              "a",
              {
                staticClass: "carousel-control-next",
                on: { click: _vm.nextItem }
              },
              [
                _c("span", {
                  staticClass: "carousel-control-next-icon",
                  attrs: { "aria-label": "Next" }
                })
              ]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }