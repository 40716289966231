var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c("CCardHeader", [
        _c("h5", {
          directives: [
            {
              name: "t",
              rawName: "v-t",
              value: "components.PlayroomDetailCancellationPart.title",
              expression: "'components.PlayroomDetailCancellationPart.title'"
            }
          ]
        })
      ]),
      _c(
        "CCardBody",
        [
          _c(
            "CRow",
            [
              _vm.playroom.allowHourBooking
                ? _c(
                    "CCol",
                    { attrs: { md: "6" } },
                    [
                      _c("div", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value:
                              "components.PlayroomDetailCancellationPart.hourly",
                            expression:
                              "'components.PlayroomDetailCancellationPart.hourly'"
                          }
                        ],
                        staticClass: "subtitle"
                      }),
                      _vm._l(_vm.playroom.hourlyCancellationRules, function(
                        rule,
                        index
                      ) {
                        return _c(
                          "CRow",
                          { key: "hourly" + index },
                          [
                            _c("CCol", {
                              directives: [
                                {
                                  name: "t",
                                  rawName: "v-t",
                                  value:
                                    "components.PlayroomDetailCancellationPart.beforeStart.hourly" +
                                    rule.beforeStart,
                                  expression:
                                    "'components.PlayroomDetailCancellationPart.beforeStart.hourly' + rule.beforeStart"
                                }
                              ],
                              attrs: { md: "8" }
                            }),
                            _c(
                              "CCol",
                              { staticClass: "percentage", attrs: { md: "4" } },
                              [
                                _vm._v(
                                  _vm._s(rule.percentage.toLocaleString()) +
                                    " %"
                                )
                              ]
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _vm.playroom.allowOvernightStay
                ? _c(
                    "CCol",
                    { attrs: { md: "6" } },
                    [
                      _c("div", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value:
                              "components.PlayroomDetailCancellationPart.overnight",
                            expression:
                              "'components.PlayroomDetailCancellationPart.overnight'"
                          }
                        ],
                        staticClass: "subtitle"
                      }),
                      _vm._l(_vm.playroom.overnightCancellationRules, function(
                        rule,
                        index
                      ) {
                        return _c(
                          "CRow",
                          { key: "overnight" + index },
                          [
                            _c("CCol", {
                              directives: [
                                {
                                  name: "t",
                                  rawName: "v-t",
                                  value:
                                    "components.PlayroomDetailCancellationPart.beforeStart.overnight" +
                                    rule.beforeStart,
                                  expression:
                                    "'components.PlayroomDetailCancellationPart.beforeStart.overnight' + rule.beforeStart"
                                }
                              ],
                              attrs: { md: "8" }
                            }),
                            _c(
                              "CCol",
                              { staticClass: "percentage", attrs: { md: "4" } },
                              [
                                _vm._v(
                                  _vm._s(rule.percentage.toLocaleString()) +
                                    " %"
                                )
                              ]
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }