var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CButton", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "components.BookingCard.reviewAndComplete",
            expression: "'components.BookingCard.reviewAndComplete'"
          }
        ],
        staticClass: "submitButton",
        attrs: {
          color: "primary",
          disabled: _vm.isButtonsDisable,
          title: _vm.title
        },
        on: { click: _vm.handleSubmit }
      }),
      !this.isCustomer
        ? _c("div", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "components.BookingCard.falseRole",
                expression: "'components.BookingCard.falseRole'"
              }
            ]
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }