var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPart
    ? _c(
        "CCard",
        [
          _c("CCardHeader", [
            _c("h5", {
              directives: [
                {
                  name: "t",
                  rawName: "v-t",
                  value: "components.PlayroomDetailDownloadsPart.title",
                  expression: "'components.PlayroomDetailDownloadsPart.title'"
                }
              ]
            })
          ]),
          _c(
            "CCardBody",
            [
              _c(
                "CRow",
                { staticClass: "linkContainer" },
                [
                  _c("CCol", { attrs: { md: "12" } }, [
                    _vm.houseRulesFile
                      ? _c("a", {
                          directives: [
                            {
                              name: "t",
                              rawName: "v-t",
                              value:
                                "components.PlayroomDetailDownloadsPart.houseRules",
                              expression:
                                "'components.PlayroomDetailDownloadsPart.houseRules'"
                            }
                          ],
                          staticClass: "btn btn-secondary",
                          attrs: {
                            role: "button",
                            href: _vm.houseRulesFile.link
                          }
                        })
                      : _vm._e()
                  ]),
                  _c("CCol", { attrs: { md: "12" } }, [
                    _vm.businessTermsFile
                      ? _c("a", {
                          directives: [
                            {
                              name: "t",
                              rawName: "v-t",
                              value:
                                "components.PlayroomDetailDownloadsPart.businessTerms",
                              expression:
                                "'components.PlayroomDetailDownloadsPart.businessTerms'"
                            }
                          ],
                          staticClass: "btn btn-secondary",
                          attrs: {
                            role: "button",
                            href: _vm.businessTermsFile.link
                          }
                        })
                      : _vm._e()
                  ]),
                  _c("CCol", { attrs: { md: "12" } }, [
                    _vm.dataPrivacyPolicyFile
                      ? _c("a", {
                          directives: [
                            {
                              name: "t",
                              rawName: "v-t",
                              value:
                                "components.PlayroomDetailDownloadsPart.dataPrivacyPolicy",
                              expression:
                                "'components.PlayroomDetailDownloadsPart.dataPrivacyPolicy'"
                            }
                          ],
                          staticClass: "btn btn-secondary",
                          attrs: {
                            role: "button",
                            href: _vm.dataPrivacyPolicyFile.link
                          }
                        })
                      : _vm._e()
                  ]),
                  _c("CCol", { attrs: { md: "12" } }, [
                    _vm.imprintRulesFile
                      ? _c("a", {
                          directives: [
                            {
                              name: "t",
                              rawName: "v-t",
                              value:
                                "components.PlayroomDetailDownloadsPart.imprint",
                              expression:
                                "'components.PlayroomDetailDownloadsPart.imprint'"
                            }
                          ],
                          staticClass: "btn btn-secondary",
                          attrs: {
                            role: "button",
                            href: _vm.imprintRulesFile.link
                          }
                        })
                      : _vm._e()
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }