var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CModal",
    {
      staticClass: "additional-options-modal",
      attrs: {
        title: _vm.$t("components.BookingCard.AdditionOptionsService.title"),
        color: "warning",
        show: true,
        centered: true,
        closeOnBackdrop: false,
        size: "lg"
      },
      on: { "update:show": _vm.onCancel },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "CButton",
                {
                  attrs: { color: "secondary", "data-testid": "cancel" },
                  on: { click: _vm.onCancel }
                },
                [_vm._v(_vm._s(_vm.$t("forms.common.cancel")))]
              ),
              _c(
                "CButton",
                {
                  attrs: { color: "primary", "data-testid": "ok" },
                  on: { click: _vm.onOk }
                },
                [_vm._v(_vm._s(_vm.$t("forms.common.ok")))]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._l(_vm.$v.internalAdditionOptions.$each.$iter, function(
        additionOption,
        index
      ) {
        return _c(
          "CRow",
          {
            key: "rowIndex" + index,
            staticClass: "additional-options-modal-row"
          },
          [
            _c(
              "CCol",
              { staticClass: "text-left", attrs: { md: "5" } },
              [
                _c("ValidatedCheckbox", {
                  attrs: {
                    field: additionOption.hasChecked,
                    checked: additionOption.hasChecked.$model,
                    label: additionOption.name.$model
                  },
                  on: {
                    "update:checked": function($event) {
                      return _vm.$set(
                        additionOption.hasChecked,
                        "$model",
                        $event
                      )
                    },
                    change: function() {
                      return _vm.onHasCheckedChange(additionOption)
                    }
                  }
                })
              ],
              1
            ),
            _c("CCol", { staticClass: "text-left", attrs: { md: "5" } }, [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.BookingCard.AdditionOptionsService." +
                          _vm.translationKey(
                            additionOption,
                            _vm.order.bookingType
                          ),
                        {
                          price: _vm.formatCurrency(
                            _vm.order.bookingType == "hourly"
                              ? additionOption.priceForHourly.$model
                              : additionOption.priceForOvernight.$model
                          )
                        }
                      )
                    ) +
                    " "
                )
              ])
            ]),
            _c("CCol", { staticClass: "text-left", attrs: { md: "2" } }, [
              additionOption.isPricePerPiece.$model
                ? _c(
                    "span",
                    [
                      _c(
                        "CButton",
                        {
                          attrs: { color: "secondary", size: "sm" },
                          on: {
                            click: function($event) {
                              return _vm.minus(additionOption)
                            }
                          }
                        },
                        [_c("CIcon", { attrs: { name: "cisMinus" } })],
                        1
                      ),
                      _c("span", { staticClass: "amount" }, [
                        _vm._v(_vm._s(additionOption.unit.$model))
                      ]),
                      _c(
                        "CButton",
                        {
                          attrs: { color: "secondary", size: "sm" },
                          on: {
                            click: function($event) {
                              return _vm.plus(additionOption)
                            }
                          }
                        },
                        [_c("CIcon", { attrs: { name: "cisPlus" } })],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ],
          1
        )
      }),
      _c("CRow", [_c("CCol", { attrs: { md: "12" } }, [_c("hr")])], 1),
      _c("AdditionalOptionsSummary", {
        attrs: {
          order: _vm.order,
          additionOptions: _vm.internalAdditionOptions,
          showSummaryTotal: true
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }