var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { md: "7" } },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "components.PlayroomDetailPricePart." + _vm.name + ".label"
                )
              ) +
              " "
          ),
          _vm.hasToolTip
            ? _c("CIcon", {
                directives: [
                  {
                    name: "c-tooltip",
                    rawName: "v-c-tooltip",
                    value: {
                      appendToBody: true,
                      content: _vm.$t(
                        "components.PlayroomDetailPricePart." +
                          _vm.name +
                          ".info"
                      )
                    },
                    expression:
                      "{ appendToBody: true, content: $t('components.PlayroomDetailPricePart.' + name + '.info') }"
                  }
                ],
                attrs: { name: "cil-info-circle" }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "CCol",
        { staticClass: "price", attrs: { md: "5" } },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "components.PlayroomDetailPricePart." + _vm.name + ".value",
                  { value: _vm.value }
                )
              ) +
              " "
          ),
          _vm._t("default")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }