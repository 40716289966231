var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "options" },
    [
      _c("CButton", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "components.BookingCard.additionOptions",
            expression: "'components.BookingCard.additionOptions'"
          }
        ],
        attrs: {
          color: "primary",
          disabled: !_vm.order.hasDates,
          title: !_vm.order.hasDates
            ? _vm.$t("components.BookingCard.additionalOptionsBtnInfo")
            : ""
        },
        on: {
          click: function($event) {
            return _vm.showAdditionOptions()
          }
        }
      }),
      _vm.showModal
        ? _c("AdditionalOptions", {
            attrs: {
              additionOptions: _vm.additionOptions,
              isHourlyBooking: _vm.order.bookingType == "hourly",
              order: _vm.order
            },
            on: {
              ok: _vm.onAdditionOptionsOk,
              cancel: _vm.onAdditionOptionsCancel
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }