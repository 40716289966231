var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c("div", [
        _vm.playroom.productVariant == "Basic"
          ? _c(
              "div",
              [
                _c(
                  "CCard",
                  [
                    _c("CCardBody", {
                      directives: [
                        {
                          name: "t",
                          rawName: "v-t",
                          value:
                            "components.PlayroomBookingCard.basicVariantText",
                          expression:
                            "'components.PlayroomBookingCard.basicVariantText'"
                        }
                      ]
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.playroom.productVariant == "Standard"
          ? _c(
              "div",
              [
                _c("BookingCardBookingTypePart", {
                  attrs: { playroom: _vm.playroom },
                  on: { onChange: _vm.onBookingTypeChange }
                }),
                _c(
                  "CCard",
                  [
                    _c(
                      "CCardBody",
                      [
                        _vm.showForHourly
                          ? _c("BookingCardHourlyBookingPart", {
                              staticClass: "pt10",
                              attrs: { playroom: _vm.playroom },
                              on: {
                                onCheckInDateChange: _vm.onCheckInDateChange,
                                onCheckOutDateChange: _vm.onCheckOutDateChange,
                                onCheckInTimeChange: _vm.onCheckInTimeChange,
                                onCheckOutTimeChange: _vm.onCheckOutTimeChange
                              }
                            })
                          : _vm._e(),
                        _vm.showForOvernight
                          ? _c("BookingCardOvernightBookingPart", {
                              staticClass: "pt10",
                              attrs: { playroom: _vm.playroom },
                              on: {
                                onCheckInDateChange: _vm.onCheckInDateChange,
                                onCheckOutDateChange: _vm.onCheckOutDateChange,
                                onCheckInTimeChange: _vm.onCheckInTimeChange,
                                onCheckOutTimeChange: _vm.onCheckOutTimeChange
                              }
                            })
                          : _vm._e(),
                        !_vm.showForOvernight &&
                        this.order.bookingType == "overnight"
                          ? _c("div", {
                              directives: [
                                {
                                  name: "t",
                                  rawName: "v-t",
                                  value:
                                    "components.PlayroomBookingCard.noOvernightBooking",
                                  expression:
                                    "'components.PlayroomBookingCard.noOvernightBooking'"
                                }
                              ]
                            })
                          : _vm._e(),
                        !_vm.showForHourly && this.order.bookingType == "hourly"
                          ? _c("div", {
                              directives: [
                                {
                                  name: "t",
                                  rawName: "v-t",
                                  value:
                                    "components.PlayroomBookingCard.noHourlyBooking",
                                  expression:
                                    "'components.PlayroomBookingCard.noHourlyBooking'"
                                }
                              ]
                            })
                          : _vm._e(),
                        _vm.showForHourly || _vm.showForOvernight
                          ? _c("BookingCardGuestPart", {
                              attrs: { playroom: _vm.playroom },
                              on: { onChange: _vm.onGuestCountChange }
                            })
                          : _vm._e(),
                        _vm.showAdditionalOptions
                          ? _c("BookingCardAdditionalOptionsPart", {
                              attrs: {
                                playroom: _vm.playroom,
                                order: _vm.order
                              },
                              on: { onChange: _vm.onAdditionalOptionsChange }
                            })
                          : _vm._e(),
                        _vm.showForOvernight
                          ? _c("BookingCardOvernightCalculationPart", {
                              attrs: {
                                playroom: _vm.playroom,
                                order: _vm.order
                              }
                            })
                          : _vm._e(),
                        _vm.showForHourly
                          ? _c("BookingCardHourlyCalculationPart", {
                              attrs: {
                                playroom: _vm.playroom,
                                order: _vm.order
                              }
                            })
                          : _vm._e(),
                        _vm.showForHourly || _vm.showForOvernight
                          ? _c("BookingCardImportantInformationPart", {
                              attrs: {
                                playroom: _vm.playroom,
                                order: _vm.order
                              }
                            })
                          : _vm._e(),
                        _vm.showForHourly || _vm.showForOvernight
                          ? _c("BookingCardButtonPart", {
                              attrs: { order: _vm.order },
                              on: { onSubmit: _vm.handleSubmit }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("BookingCardConfirmationModal", {
                  attrs: {
                    show: _vm.showConfirmationModal,
                    orderId: _vm.orderIdForConfirmationModal,
                    playroom: _vm.playroom,
                    order: _vm.order
                  },
                  on: {
                    onUpdateShow: function(show) {
                      return (_vm.showConfirmationModal = show)
                    }
                  }
                }),
                _vm.showProfileModal
                  ? _c(
                      "CModal",
                      {
                        attrs: {
                          title: "Profile",
                          color: "warning",
                          show: _vm.showProfileModal,
                          closeOnBackdrop: false,
                          size: "lg",
                          centered: true
                        },
                        on: {
                          "update:show": [
                            function($event) {
                              _vm.showProfileModal = $event
                            },
                            _vm.showProfileModal
                          ]
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "footer",
                              fn: function() {
                                return [_c("div")]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3545152822
                        )
                      },
                      [
                        _c("CustomerProfileForm", {
                          on: {
                            profileSaved: function() {
                              return _vm.profileSaved()
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c("LoginModal", {
                  attrs: { show: _vm.showLoginModal },
                  on: {
                    "update:show": function($event) {
                      _vm.showLoginModal = $event
                    },
                    onUpdateShow: _vm.onUpdateShow
                  }
                })
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }