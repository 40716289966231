var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.questions.length > 0
    ? _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { md: "12" } },
            [
              _c("CCardHeader", [
                _c("h5", {
                  directives: [
                    {
                      name: "t",
                      rawName: "v-t",
                      value: "components.PlayroomDetailFaqPart.title",
                      expression: "'components.PlayroomDetailFaqPart.title'"
                    }
                  ]
                })
              ]),
              _c(
                "CCard",
                [
                  _c(
                    "CListGroup",
                    { attrs: { flush: true } },
                    _vm._l(_vm.questions, function(faq, index) {
                      return _c("CListGroupItem", { key: "faq" + index }, [
                        _c("div", { staticClass: "question" }, [
                          _vm._v(_vm._s(faq.question))
                        ]),
                        _c("br"),
                        _vm._v(" " + _vm._s(faq.answer) + " ")
                      ])
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }