var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { md: "12" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [
                    _c("h5", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("components.PlayroomDetailHostPart.title", {
                              ownerName: _vm.playroom.ownerDisplayName
                            })
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CRow",
                        [
                          _c("CCol", { attrs: { md: "9" } }, [
                            _vm.playroom.welcomeMessage
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.playroom.welcomeMessage) +
                                      " "
                                  )
                                ])
                              : _vm._e()
                          ]),
                          !_vm.loggedIn
                            ? _c(
                                "CCol",
                                { attrs: { md: "3" } },
                                [
                                  _c("CButton", {
                                    directives: [
                                      {
                                        name: "t",
                                        rawName: "v-t",
                                        value:
                                          "components.PlayroomDetailHostPart.contactHostButton",
                                        expression:
                                          "'components.PlayroomDetailHostPart.contactHostButton'"
                                      }
                                    ],
                                    attrs: { color: "secondary" },
                                    on: { click: _vm.openLoginModal }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.loggedInAsCustomer
                            ? _c(
                                "CCol",
                                { attrs: { md: "3" } },
                                [
                                  _c("CButton", {
                                    directives: [
                                      {
                                        name: "t",
                                        rawName: "v-t",
                                        value:
                                          "components.PlayroomDetailHostPart.contactHostButton",
                                        expression:
                                          "'components.PlayroomDetailHostPart.contactHostButton'"
                                      }
                                    ],
                                    attrs: { color: "secondary" },
                                    on: { click: _vm.openMessageToHostModal }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("MessageToHostModal", {
        attrs: { show: _vm.showMessageToHostModal, playroom: _vm.playroom },
        on: { onUpdateShow: _vm.closeMessageToHostModal }
      }),
      _c("LoginModal", {
        attrs: { show: _vm.showLoginModal },
        on: {
          "update:show": function($event) {
            _vm.showLoginModal = $event
          },
          onUpdateShow: _vm.onUpdateShow
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }