var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    { staticClass: "playroom-detail-key-feature" },
    [
      _c("CCardBody", [
        _c("div", { staticClass: "key-feature" }, [
          _vm.playroom.propertyType
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "icon" },
                  [_c("CIcon", { attrs: { name: "cilHome", size: "xl" } })],
                  1
                ),
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "enums.displayedPropertyTypes." +
                          _vm.playroom.propertyType
                      )
                    )
                  )
                ])
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "key-feature" }, [
          _c(
            "div",
            { staticClass: "icon" },
            [_c("CIcon", { attrs: { name: "cilPerson", size: "xl" } })],
            1
          ),
          _c("div", { staticClass: "text" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("components.PlayroomDetailKeyFeaturePart.maxPersons", {
                    maxPersons: _vm.playroom.maxPersons
                  })
                ) +
                " "
            )
          ])
        ]),
        _vm.playroom.hasSelfCheckIn
          ? _c(
              "div",
              { staticClass: "key-feature" },
              [
                _c("CIcon", { attrs: { name: "cisKeyAlt", size: "xl" } }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.PlayroomDetailKeyFeaturePart.hasSelfCheckIn"
                      )
                    ) +
                    " "
                )
              ],
              1
            )
          : _vm._e(),
        _vm.playroom.smokingAllowed
          ? _c(
              "div",
              { staticClass: "key-feature" },
              [
                _c("CIcon", { attrs: { name: "cisSmoke", size: "xl" } }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.PlayroomDetailKeyFeaturePart.smokingAllowed"
                      )
                    ) +
                    " "
                )
              ],
              1
            )
          : _vm._e(),
        !_vm.playroom.smokingAllowed
          ? _c(
              "div",
              { staticClass: "key-feature" },
              [
                _c("CIcon", { attrs: { name: "cisSmokeFree", size: "xl" } }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.PlayroomDetailKeyFeaturePart.smokingNotAllowed"
                      )
                    ) +
                    " "
                )
              ],
              1
            )
          : _vm._e(),
        _vm.playroom.petsAllowed
          ? _c(
              "div",
              { staticClass: "key-feature" },
              [
                _c("PetsAllowedIcon"),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.PlayroomDetailKeyFeaturePart.petsAllowed"
                      )
                    ) +
                    " "
                )
              ],
              1
            )
          : _vm._e(),
        _vm.playroom.partyEventAllowed
          ? _c(
              "div",
              { staticClass: "key-feature" },
              [
                _c("PartyEventAllowedIcon"),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.PlayroomDetailKeyFeaturePart.partyEventAllowed"
                      )
                    ) +
                    " "
                )
              ],
              1
            )
          : _vm._e(),
        !_vm.playroom.partyEventAllowed
          ? _c(
              "div",
              { staticClass: "key-feature" },
              [
                _c("PartyEventNotAllowedIcon"),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.PlayroomDetailKeyFeaturePart.partyEventNotAllowed"
                      )
                    ) +
                    " "
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }