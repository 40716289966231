var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "playroomTitle" },
    [
      !_vm.playroom.isActive
        ? _c(
            "CCol",
            { staticClass: "bg-warning p-3 mb-2 lead", attrs: { md: "12" } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("components.PlayroomDetailTitlePart.notActive")
                  ) +
                  " "
              ),
              _vm.currentUser.role == "Owner"
                ? _c("CButton", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value:
                          "components.PlayroomDetailTitlePart.changePlayroom",
                        expression:
                          "'components.PlayroomDetailTitlePart.changePlayroom'"
                      }
                    ],
                    staticClass: "change-playroom",
                    attrs: {
                      color: "primary",
                      to: {
                        name: "OwnerPlayroomEditBasicInformation",
                        playroomId: _vm.playroom.id
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("CCol", { staticClass: "playroomName", attrs: { md: "12" } }, [
        _c("h3", [_vm._v(_vm._s(_vm.playroom.name))])
      ]),
      _c("CCol", { attrs: { md: "6" } }, [
        _vm.playroom.addressCity
          ? _c(
              "span",
              [
                _c("CIcon", { attrs: { name: "cisLocationPin" } }),
                !_vm.playroom.address.hideAddress
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.playroom.address.street) +
                          " " +
                          _vm._s(_vm.playroom.address.houseNumber) +
                          ", "
                      )
                    ])
                  : _vm._e(),
                _vm._v(
                  " " +
                    _vm._s(_vm.playroom.addressZipCode) +
                    " " +
                    _vm._s(_vm.playroom.addressCity) +
                    " "
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "CCol",
        { attrs: { md: "5" } },
        [
          _c("Rating", {
            attrs: {
              rating: { rating: _vm.playroom.averageRating },
              ratingCount: _vm.playroom.ratingCount,
              displayInline: true,
              withRatingDescription: true
            }
          })
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "1" } },
        [_c("PlayroomFavoriteIcon", { attrs: { playroom: _vm.playroom } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }