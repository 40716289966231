var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ValidatedCheckbox", {
        attrs: {
          field: _vm.$v.acceptBusinessTerms,
          checked: _vm.$v.acceptBusinessTerms.$model,
          translationKey: "booking.acceptBusinessTerms",
          label: _vm
            .$t("forms.booking.acceptBusinessTerms.label", {
              link: _vm.order.businessTermsFileLink
            })
            .toString()
        },
        on: {
          "update:checked": function($event) {
            return _vm.$set(_vm.$v.acceptBusinessTerms, "$model", $event)
          }
        }
      }),
      _vm.hasHouseRules
        ? _c("ValidatedCheckbox", {
            attrs: {
              field: _vm.$v.acceptHouseRules,
              checked: _vm.$v.acceptHouseRules.$model,
              translationKey: "booking.acceptHouseRules",
              label: _vm
                .$t("forms.booking.acceptHouseRules.label", {
                  link: _vm.order.houseRulesFileLink
                })
                .toString()
            },
            on: {
              "update:checked": function($event) {
                return _vm.$set(_vm.$v.acceptHouseRules, "$model", $event)
              }
            }
          })
        : _vm._e(),
      _vm.hasPrivacyPolicy
        ? _c("ValidatedCheckbox", {
            attrs: {
              field: _vm.$v.acceptPrivacyPolicy,
              checked: _vm.$v.acceptPrivacyPolicy.$model,
              translationKey: "booking.acceptPrivacyPolicy",
              label: _vm
                .$t("forms.booking.acceptPrivacyPolicy.label", {
                  link: _vm.order.privacyPolicyFileLink
                })
                .toString()
            },
            on: {
              "update:checked": function($event) {
                return _vm.$set(_vm.$v.acceptPrivacyPolicy, "$model", $event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }