var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CModal",
    {
      staticClass: "supportmodal",
      attrs: {
        title: _vm.popupTitle,
        color: "warning",
        show: _vm.show,
        "data-cy": "cancel-modal",
        closeOnBackdrop: false,
        centered: true
      },
      on: {
        "update:show": function($event) {
          return _vm.$emit("onClose")
        }
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("CButton", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "components.PlayroomDetailPricePart.priceClose",
                    expression:
                      "'components.PlayroomDetailPricePart.priceClose'"
                  }
                ],
                attrs: { color: "light" },
                on: {
                  click: function($event) {
                    return _vm.$emit("onClose")
                  }
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "CRow",
        [
          _c("CCol", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "forms.playroom.hourlyWeekPrice.monday.label",
                expression: "'forms.playroom.hourlyWeekPrice.monday.label'"
              }
            ],
            attrs: { md: "6" }
          }),
          _c("CCol", { staticClass: "price", attrs: { md: "6" } }, [
            _vm._v(
              " " + _vm._s(_vm.formatCurrency(_vm.PlayroomPriceDto.monday))
            )
          ])
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c("CCol", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "forms.playroom.hourlyWeekPrice.tuesday.label",
                expression: "'forms.playroom.hourlyWeekPrice.tuesday.label'"
              }
            ],
            attrs: { md: "6" }
          }),
          _c("CCol", { staticClass: "price", attrs: { md: "6" } }, [
            _vm._v(
              " " + _vm._s(_vm.formatCurrency(_vm.PlayroomPriceDto.tuesday))
            )
          ])
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c("CCol", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "forms.playroom.hourlyWeekPrice.wednesday.label",
                expression: "'forms.playroom.hourlyWeekPrice.wednesday.label'"
              }
            ],
            attrs: { md: "6" }
          }),
          _c("CCol", { staticClass: "price", attrs: { md: "6" } }, [
            _vm._v(
              " " + _vm._s(_vm.formatCurrency(_vm.PlayroomPriceDto.wednesday))
            )
          ])
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c("CCol", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "forms.playroom.hourlyWeekPrice.thursday.label",
                expression: "'forms.playroom.hourlyWeekPrice.thursday.label'"
              }
            ],
            attrs: { md: "6" }
          }),
          _c("CCol", { staticClass: "price", attrs: { md: "6" } }, [
            _vm._v(
              " " + _vm._s(_vm.formatCurrency(_vm.PlayroomPriceDto.thursday))
            )
          ])
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c("CCol", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "forms.playroom.hourlyWeekPrice.friday.label",
                expression: "'forms.playroom.hourlyWeekPrice.friday.label'"
              }
            ],
            attrs: { md: "6" }
          }),
          _c("CCol", { staticClass: "price", attrs: { md: "6" } }, [
            _vm._v(
              " " + _vm._s(_vm.formatCurrency(_vm.PlayroomPriceDto.friday))
            )
          ])
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c("CCol", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "forms.playroom.hourlyWeekPrice.saturday.label",
                expression: "'forms.playroom.hourlyWeekPrice.saturday.label'"
              }
            ],
            attrs: { md: "6" }
          }),
          _c("CCol", { staticClass: "price", attrs: { md: "6" } }, [
            _vm._v(
              " " + _vm._s(_vm.formatCurrency(_vm.PlayroomPriceDto.saturday))
            )
          ])
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c("CCol", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "forms.playroom.hourlyWeekPrice.sunday.label",
                expression: "'forms.playroom.hourlyWeekPrice.sunday.label'"
              }
            ],
            attrs: { md: "6" }
          }),
          _c("CCol", { staticClass: "price", attrs: { md: "6" } }, [
            _vm._v(
              " " + _vm._s(_vm.formatCurrency(_vm.PlayroomPriceDto.sunday))
            )
          ])
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c("CCol", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "forms.playroom.hourlyWeekPrice.onHoliday.label",
                expression: "'forms.playroom.hourlyWeekPrice.onHoliday.label'"
              }
            ],
            attrs: { md: "6" }
          }),
          _c("CCol", { staticClass: "price", attrs: { md: "6" } }, [
            _vm._v(
              " " + _vm._s(_vm.formatCurrency(_vm.PlayroomPriceDto.onHoliday))
            )
          ])
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c("CCol", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "forms.playroom.hourlyWeekPrice.beforeHoliday.label",
                expression:
                  "'forms.playroom.hourlyWeekPrice.beforeHoliday.label'"
              }
            ],
            attrs: { md: "6" }
          }),
          _c("CCol", { staticClass: "price", attrs: { md: "6" } }, [
            _vm._v(
              " " +
                _vm._s(_vm.formatCurrency(_vm.PlayroomPriceDto.beforeHoliday))
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }