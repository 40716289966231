var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c(
        "CCardBody",
        [
          !_vm.isDateTimesValid
            ? _c("div", [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.BookingCard.BookingTypePart.hourlyRate"
                        )
                      ) +
                      " "
                  )
                ]),
                _c("span", { staticClass: "text-right" }, [
                  _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("components.BookingCard.Fromlabel")) +
                        " "
                    )
                  ]),
                  _vm._v(
                    " " + _vm._s(_vm.formatCurrency(this.playroom.pricePerHour))
                  )
                ])
              ])
            : _vm._e(),
          _vm.isDateTimesValid
            ? _c("div", [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$tc(
                          "components.BookingCard.BookingTypePart.hourlyTotalPrice",
                          Math.ceil(this.order.totalHours),
                          {
                            price: _vm.formatCurrency(_vm.basePrice),
                            getTotalCheckInTime: _vm.order.totalHours.toLocaleString(
                              "de"
                            )
                          }
                        )
                      ) +
                      " "
                  )
                ]),
                _c("span", { staticClass: "text-right" }, [
                  _vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalPrice)) + " ")
                ])
              ])
            : _vm._e(),
          _vm.playroom.baseRentForHourly > 0
            ? _c("div", [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.BookingCard.BookingTypePart.basePrice"
                        )
                      ) +
                      " "
                  )
                ]),
                _c("span", { staticClass: "text-right" }, [
                  _vm._v(
                    _vm._s(_vm.formatCurrency(_vm.playroom.baseRentForHourly))
                  )
                ])
              ])
            : _vm._e(),
          _vm.order.hasDates && _vm.getSurchargePerPersonPerHour > 0
            ? _c("div", [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.BookingCard.BookingTypePart.hourlyPersonSurcharge"
                        )
                      ) +
                      " "
                  )
                ]),
                _c("span", { staticClass: "text-right" }, [
                  _vm._v(
                    _vm._s(_vm.formatCurrency(_vm.getSurchargePerPersonPerHour))
                  )
                ])
              ])
            : _vm._e(),
          _vm.order.countOfGuests > 2 &&
          _vm.playroom.surchargePerPersonPerBookingForHourly > 0
            ? _c("div", [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.BookingCard.BookingTypePart.bookingTotalSurcharge",
                          {
                            surcharge: _vm.formatCurrency(
                              _vm.playroom.surchargePerPersonPerBookingForHourly
                            ),
                            guestCount: _vm.getExtraGuests
                          }
                        )
                      ) +
                      " "
                  )
                ]),
                _c("span", { staticClass: "text-right" }, [
                  _vm._v(
                    _vm._s(
                      _vm.formatCurrency(_vm.getSurchargePerPersonPerBooking)
                    )
                  )
                ])
              ])
            : _vm._e(),
          _c("AdditionalOptionsSummary", {
            attrs: {
              order: _vm.order,
              additionOptions: _vm.order.additions,
              showSummaryTotal: false,
              isHourlyBooking: true
            }
          }),
          _vm.isDateTimesValid
            ? _c("div", [
                _c("span", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("components.BookingCard.totalAmount")))
                  ])
                ]),
                _c("span", { staticClass: "text-right" }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.formatCurrency(+_vm.totalAmount)))
                  ])
                ])
              ])
            : _vm._e(),
          _vm.isDateTimesValid
            ? _c("div", [
                _c("span", [
                  _c("em", [
                    _c("small", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("components.BookingCard.conditionStar"))
                      )
                    ])
                  ])
                ])
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }