var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.playroom.houseRules.length > 0
    ? _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { md: "12" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [
                    _c("h5", {
                      directives: [
                        {
                          name: "t",
                          rawName: "v-t",
                          value:
                            "components.PlayroomDetailHouseRulesPart.title",
                          expression:
                            "'components.PlayroomDetailHouseRulesPart.title'"
                        }
                      ]
                    })
                  ]),
                  _c(
                    "CListGroup",
                    { attrs: { flush: true } },
                    _vm._l(_vm.playroom.houseRules, function(houseRule, index) {
                      return _c("CListGroupItem", { key: index }, [
                        _vm._v(" " + _vm._s(houseRule) + " ")
                      ])
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }