var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "CCard",
        [
          _c("CCardHeader", [
            _c(
              "h5",
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("components.PlayroomDetailFeaturePart.title")
                    ) +
                    " "
                ),
                _c("CIcon", {
                  directives: [
                    {
                      name: "c-tooltip",
                      rawName: "v-c-tooltip",
                      value: {
                        appendToBody: true,
                        content: _vm.$t(
                          "components.PlayroomDetailFeaturePart.tooltip"
                        )
                      },
                      expression:
                        "{ appendToBody: true, content: $t('components.PlayroomDetailFeaturePart.tooltip') }"
                    }
                  ],
                  attrs: { name: "cis-info-circle" }
                })
              ],
              1
            )
          ]),
          _c(
            "CCardBody",
            [
              _vm._l(_vm.playroomFeatures, function(featureCategory) {
                return _c(
                  "div",
                  { key: featureCategory.name },
                  [
                    _c("div", {
                      directives: [
                        {
                          name: "t",
                          rawName: "v-t",
                          value:
                            "featureCategories." +
                            featureCategory.translationKey,
                          expression:
                            "'featureCategories.' + featureCategory.translationKey"
                        }
                      ],
                      staticClass: "subtitle"
                    }),
                    _c(
                      "CRow",
                      _vm._l(featureCategory.features, function(feature) {
                        return _c(
                          "CCol",
                          { key: feature.translationKey, attrs: { md: "4" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("features." + feature.translationKey)
                                ) +
                                " "
                            ),
                            feature.hasFee
                              ? _c("CIcon", {
                                  directives: [
                                    {
                                      name: "c-tooltip",
                                      rawName: "v-c-tooltip",
                                      value: {
                                        appendToBody: true,
                                        content: _vm.$t(
                                          "components.PlayroomDetailFeaturePart.feeTooltip"
                                        )
                                      },
                                      expression:
                                        "{ appendToBody: true, content: $t('components.PlayroomDetailFeaturePart.feeTooltip') }"
                                    }
                                  ],
                                  attrs: { name: "cisEuro" }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              }),
              _vm.playroomCustomFeatures.length > 0
                ? _c(
                    "div",
                    [
                      _c("div", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value: "featureCategories.CustomFeatures",
                            expression: "'featureCategories.CustomFeatures'"
                          }
                        ],
                        staticClass: "subtitle"
                      }),
                      _c(
                        "CRow",
                        _vm._l(_vm.playroomCustomFeatures, function(feature) {
                          return _c(
                            "CCol",
                            { key: feature.id, attrs: { md: "4" } },
                            [
                              _vm._v(" " + _vm._s(feature.name) + " "),
                              feature.hasFee
                                ? _c("CIcon", {
                                    directives: [
                                      {
                                        name: "c-tooltip",
                                        rawName: "v-c-tooltip",
                                        value: {
                                          appendToBody: true,
                                          content: _vm.$t(
                                            "components.PlayroomDetailFeaturePart.feeTooltip"
                                          )
                                        },
                                        expression:
                                          "{ appendToBody: true, content: $t('components.PlayroomDetailFeaturePart.feeTooltip') }"
                                      }
                                    ],
                                    attrs: { name: "cisEuro" }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }