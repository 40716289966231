var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ValidatedInput", {
        attrs: {
          translationKey: "playroomSearch.countOfGuest",
          type: "number",
          min: "2",
          max: _vm.playroom.maxPersons,
          field: _vm.$v.countOfGuests,
          value: _vm.$v.countOfGuests.$model
        },
        on: {
          "update:value": function($event) {
            return _vm.$set(_vm.$v.countOfGuests, "$model", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }