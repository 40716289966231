var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.getSummary.length > 0
    ? _c(
        "div",
        { attrs: { testid: "summary" } },
        [
          _vm.showSummaryTotal
            ? _c(
                "CRow",
                [
                  _c("CCol", { attrs: { md: "12" } }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.BookingCard.AdditionOptionsService.summary"
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.getSummary, function(additionOption, index) {
            return _c(
              "CRow",
              { key: "summary" + index },
              [
                _c("CCol", { staticClass: "text-left", attrs: { md: "9" } }, [
                  _vm._v(" " + _vm._s(additionOption.name) + ": "),
                  _c("span", { staticClass: "price" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.BookingCard.AdditionOptionsService.summaryPrice",
                            {
                              price: _vm.formatCurrency(
                                _vm.getPrice(additionOption)
                              )
                            }
                          )
                        ) +
                        " "
                    ),
                    additionOption.isPricePerPiece
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$tc(
                                  "components.BookingCard.AdditionOptionsService.summaryPiece",
                                  additionOption.unit,
                                  { unit: additionOption.unit }
                                )
                              ) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    additionOption.isPricePerTime &&
                    _vm.order.bookingType == "hourly"
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$tc(
                                  "components.BookingCard.AdditionOptionsService.summaryTimeHourly",
                                  _vm.order.totalHours,
                                  { unit: _vm.order.totalHours }
                                )
                              ) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    additionOption.isPricePerTime &&
                    _vm.order.bookingType == "overnight"
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$tc(
                                  "components.BookingCard.AdditionOptionsService.summaryTimeOvernight",
                                  _vm.order.totalDays,
                                  { unit: _vm.order.totalDays }
                                )
                              ) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("CCol", { staticClass: "text-right", attrs: { md: "3" } }, [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formatCurrency(
                            _vm.getSum(additionOption, _vm.order)
                          )
                        ) +
                        " "
                    )
                  ])
                ])
              ],
              1
            )
          }),
          _vm.showSummaryTotal
            ? _c(
                "CRow",
                [
                  _c("CCol", { staticClass: "text-left", attrs: { md: "9" } }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.BookingCard.AdditionOptionsService.summaryTotal"
                          )
                        )
                      )
                    ])
                  ]),
                  _c(
                    "CCol",
                    {
                      staticClass: "text-right",
                      attrs: { md: "3", "data-cy": "total" }
                    },
                    [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.formatCurrency(_vm.summaryTotal)))
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }