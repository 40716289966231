var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready && _vm.images.length > 0 && _vm.isDarkMode
    ? _c(
        "CCard",
        { staticClass: "playroom-detail-image-part" },
        [
          _c(
            "CCardBody",
            [
              _c(
                "CRow",
                [
                  _c(
                    "CCol",
                    { staticClass: "firstImage", attrs: { md: "6" } },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.firstImage.link,
                          alt: _vm.firstImage.title
                        },
                        on: {
                          click: function($event) {
                            return _vm.openModal(0)
                          }
                        }
                      })
                    ]
                  ),
                  _vm.images.length > 1
                    ? _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "CRow",
                            _vm._l(_vm.otherImages, function(image, index) {
                              return _c(
                                "CCol",
                                {
                                  key: image.id,
                                  staticClass: "otherImages",
                                  attrs: { md: "6" }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: image.link,
                                      alt: image.title
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openModal(index + 1)
                                      }
                                    }
                                  })
                                ]
                              )
                            }),
                            1
                          ),
                          _vm.showMoreImagesButton
                            ? _c(
                                "CButton",
                                {
                                  attrs: { color: "secondary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openModal(5)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.PlayroomDetailImagePart.moreImages",
                                          {
                                            imageCount: _vm.additionalImageCount
                                          }
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CModal",
            {
              attrs: {
                title: _vm.playroom.name,
                centered: true,
                show: _vm.showModal,
                size: "xl"
              },
              on: { "update:show": _vm.onClose },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function() {
                      return [
                        _c("CButton", {
                          directives: [
                            {
                              name: "t",
                              rawName: "v-t",
                              value:
                                "components.PlayroomDetailImagePart.modalClose",
                              expression:
                                "'components.PlayroomDetailImagePart.modalClose'"
                            }
                          ],
                          attrs: { color: "light" },
                          on: {
                            click: function($event) {
                              return _vm.onClose()
                            }
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2343849146
              )
            },
            [
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  _vm._s(_vm.modalActiveImage + 1) +
                    " / " +
                    _vm._s(_vm.images.length)
                )
              ]),
              _c(
                "SmCarousel",
                {
                  ref: "carousel",
                  attrs: { arrows: _vm.images.length > 1, animate: "" },
                  on: { onChange: _vm.onImageChange }
                },
                _vm._l(_vm.images, function(image, index) {
                  return _c("CCarouselItem", {
                    key: index,
                    staticClass: "playroom-detail-carousel-image",
                    attrs: {
                      image: image.link,
                      active: _vm.modalActiveImage == index
                    }
                  })
                }),
                1
              ),
              _c("div", { staticClass: "text title" }, [
                _vm._v(_vm._s(_vm.captionTitle(_vm.modalActiveImage)))
              ]),
              _c("div", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.captionDescription(_vm.modalActiveImage)))
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }