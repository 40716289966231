var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _vm.playroom.allowOvernightStay
        ? _c(
            "CCol",
            { attrs: { md: _vm.colSize } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [
                    _c("h5", {
                      directives: [
                        {
                          name: "t",
                          rawName: "v-t",
                          value:
                            "components.PlayroomDetailAdditionPart.titleOvernight",
                          expression:
                            "'components.PlayroomDetailAdditionPart.titleOvernight'"
                        }
                      ]
                    })
                  ]),
                  _c(
                    "CCardBody",
                    [
                      _vm.sortedAdditionsForOvernight.length == 0
                        ? _c("div", {
                            directives: [
                              {
                                name: "t",
                                rawName: "v-t",
                                value:
                                  "components.PlayroomDetailAdditionPart.noAdditionalOptions",
                                expression:
                                  "'components.PlayroomDetailAdditionPart.noAdditionalOptions'"
                              }
                            ]
                          })
                        : _vm._e(),
                      _vm._l(_vm.sortedAdditionsForOvernight, function(
                        addition
                      ) {
                        return _c(
                          "CRow",
                          { key: addition.id + "overnight" },
                          [
                            _c(
                              "CCol",
                              { attrs: { md: "7" } },
                              [
                                _vm._v(" " + _vm._s(addition.name) + " "),
                                addition.description
                                  ? _c("CIcon", {
                                      directives: [
                                        {
                                          name: "c-tooltip",
                                          rawName: "v-c-tooltip",
                                          value: {
                                            appendToBody: true,
                                            content: addition.description
                                          },
                                          expression:
                                            "{ appendToBody: true, content: addition.description }"
                                        }
                                      ],
                                      attrs: { name: "cil-info-circle" }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "CCol",
                              { staticClass: "price", attrs: { md: "5" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatCurrency(
                                        addition.priceForOvernight
                                      )
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.PlayroomDetailAdditionPart.unit" +
                                          _vm.unitType(addition, "Overnight")
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.playroom.allowHourBooking
        ? _c(
            "CCol",
            { attrs: { md: _vm.colSize } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [
                    _c("h5", {
                      directives: [
                        {
                          name: "t",
                          rawName: "v-t",
                          value:
                            "components.PlayroomDetailAdditionPart.titleHourly",
                          expression:
                            "'components.PlayroomDetailAdditionPart.titleHourly'"
                        }
                      ]
                    })
                  ]),
                  _c(
                    "CCardBody",
                    [
                      _vm.sortedAdditionsForHourly.length == 0
                        ? _c("div", {
                            directives: [
                              {
                                name: "t",
                                rawName: "v-t",
                                value:
                                  "components.PlayroomDetailAdditionPart.noAdditionalOptions",
                                expression:
                                  "'components.PlayroomDetailAdditionPart.noAdditionalOptions'"
                              }
                            ]
                          })
                        : _vm._e(),
                      _vm._l(_vm.sortedAdditionsForHourly, function(addition) {
                        return _c(
                          "CRow",
                          { key: addition.id + "hourly" },
                          [
                            _c(
                              "CCol",
                              { attrs: { md: "7" } },
                              [
                                _vm._v(" " + _vm._s(addition.name) + " "),
                                addition.description
                                  ? _c("CIcon", {
                                      directives: [
                                        {
                                          name: "c-tooltip",
                                          rawName: "v-c-tooltip",
                                          value: {
                                            appendToBody: true,
                                            content: addition.description
                                          },
                                          expression:
                                            "{ appendToBody: true, content: addition.description }"
                                        }
                                      ],
                                      attrs: { name: "cil-info-circle" }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "CCol",
                              { staticClass: "price", attrs: { md: "5" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatCurrency(
                                        addition.priceForHourly
                                      )
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.PlayroomDetailAdditionPart.unit" +
                                          _vm.unitType(addition, "Hourly")
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }