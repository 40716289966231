var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "booking-card-confirmation-modal" },
    [
      _c(
        "CModal",
        {
          attrs: { show: _vm.show, title: _vm.title, size: "xl" },
          on: { "update:show": _vm.onUpdateShow },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c("CButton", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value:
                          "components.BookingCardParts.BookingCardConfirmationModal.cancel",
                        expression:
                          "'components.BookingCardParts.BookingCardConfirmationModal.cancel'"
                      }
                    ],
                    attrs: {
                      color: "secondary",
                      "data-testid": "cancel",
                      disabled: _vm.loading
                    },
                    on: {
                      click: function() {
                        return _vm.onUpdateShow(false)
                      }
                    }
                  }),
                  _c(
                    "CButton",
                    {
                      attrs: {
                        color: "primary",
                        "data-testid": "ok",
                        disabled: !_vm.canConfirm
                      },
                      on: { click: _vm.onConfirm }
                    },
                    [
                      _c("span", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loading,
                            expression: "loading"
                          }
                        ],
                        staticClass: "spinner-border spinner-border-sm"
                      }),
                      _c("span", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value:
                              "components.BookingCardParts.BookingCardConfirmationModal.sendRequest",
                            expression:
                              "'components.BookingCardParts.BookingCardConfirmationModal.sendRequest'"
                          }
                        ]
                      })
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.ready
            ? _c(
                "div",
                { staticClass: "confirmationmodal" },
                [
                  _c("OrderDetailGeneralInformationPart", {
                    attrs: { order: _vm.order }
                  }),
                  _c("OrderDetailPricePart", {
                    attrs: { order: _vm.order, showTax: false }
                  }),
                  _c("OrderDetailImportantInformationPart", {
                    attrs: { order: _vm.order }
                  }),
                  _c("BookingCardPersonalNoticePart", {
                    attrs: { order: _vm.order }
                  }),
                  _c("BookingCardConfirmationCheckboxes", {
                    attrs: { order: _vm.order },
                    on: { onChange: _vm.onChangeCheckboxes }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c("Notification", {
        attrs: { text: _vm.notificationText, show: _vm.showNotification },
        on: {
          onClose: function() {
            return _vm.handleCloseNotification()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }