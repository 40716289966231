var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CContainer",
    [
      !_vm.ready ? _c("CElementCover", { attrs: { opacity: 0.5 } }) : _vm._e(),
      _vm.ready
        ? _c(
            "CRow",
            [
              _c(
                "CCol",
                { attrs: { md: "4" } },
                [
                  _c("PlayroomBookingCard", {
                    attrs: { playroomId: _vm.playroom.id }
                  })
                ],
                1
              ),
              _c(
                "CCol",
                { attrs: { md: "8" } },
                [
                  _c("PlayroomDetailTitlePart", {
                    attrs: { playroom: _vm.playroom }
                  }),
                  _c("PlayroomDetailImagePart", {
                    attrs: { playroom: _vm.playroom }
                  }),
                  _c("PlayroomDetailKeyFeaturePart", {
                    attrs: { playroom: _vm.playroom }
                  }),
                  _c("PlayroomDetailDescriptionPart", {
                    attrs: { playroom: _vm.playroom }
                  }),
                  _c("PlayroomDetailPricePart", {
                    attrs: { playroom: _vm.playroom }
                  }),
                  _c("PlayroomDetailAreaPart", {
                    attrs: { playroom: _vm.playroom }
                  }),
                  _c("PlayroomDetailFeaturePart", {
                    attrs: { playroom: _vm.playroom }
                  }),
                  _c("PlayroomDetailAdditionsPart", {
                    attrs: { playroom: _vm.playroom }
                  }),
                  _c("PlayroomDetailRatingPart", {
                    attrs: { playroom: _vm.playroom }
                  }),
                  _c("PlayroomDetailHighlightsPart", {
                    attrs: { playroom: _vm.playroom }
                  }),
                  _c("PlayroomDetailLocationPart", {
                    attrs: { playroom: _vm.playroom }
                  }),
                  _c("PlayroomDetailHostPart", {
                    attrs: { playroom: _vm.playroom }
                  }),
                  _c("PlayroomDetailHouseRulesPart", {
                    attrs: { playroom: _vm.playroom }
                  }),
                  _c("PlayroomDetailDownloadsPart", {
                    attrs: { playroom: _vm.playroom }
                  }),
                  _c("PlayroomDetailCancellationPart", {
                    attrs: { playroom: _vm.playroom }
                  }),
                  _c("PlayroomDetailPrePaymentPart", {
                    attrs: { playroom: _vm.playroom }
                  }),
                  _c("PlayroomDetailFaqPart", {
                    attrs: { playroom: _vm.playroom }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }