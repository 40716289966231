var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CCard",
        [
          _c("CCardHeader", [
            _c("h5", {
              directives: [
                {
                  name: "t",
                  rawName: "v-t",
                  value: "components.PlayroomDetailPrePaymentPart.title",
                  expression: "'components.PlayroomDetailPrePaymentPart.title'"
                }
              ]
            })
          ]),
          _c(
            "CCardBody",
            [
              _vm.prepaymentForHourlyBooking > 0
                ? _c(
                    "CRow",
                    [
                      _c("CCol", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value:
                              "components.PlayroomDetailPrePaymentPart.hourlyPrepayment",
                            expression:
                              "'components.PlayroomDetailPrePaymentPart.hourlyPrepayment'"
                          }
                        ],
                        attrs: { md: "6" }
                      }),
                      _c("CCol", { attrs: { md: "6" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.PlayroomDetailPrePaymentPart.percentage",
                                {
                                  percentage: _vm.prepaymentForHourlyBooking.toLocaleString()
                                }
                              )
                            ) +
                            " "
                        ),
                        _vm.playroom.prepaymentOnlyForNewGuests
                          ? _c("span", {
                              directives: [
                                {
                                  name: "t",
                                  rawName: "v-t",
                                  value:
                                    "components.PlayroomDetailPrePaymentPart.onlyForNewGuests",
                                  expression:
                                    "'components.PlayroomDetailPrePaymentPart.onlyForNewGuests'"
                                }
                              ]
                            })
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.prepaymentForOvernightBooking > 0
                ? _c(
                    "CRow",
                    [
                      _c("CCol", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value:
                              "components.PlayroomDetailPrePaymentPart.overnightPrepayment",
                            expression:
                              "'components.PlayroomDetailPrePaymentPart.overnightPrepayment'"
                          }
                        ],
                        attrs: { md: "6" }
                      }),
                      _c("CCol", { attrs: { md: "6" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.PlayroomDetailPrePaymentPart.percentage",
                                {
                                  percentage: _vm.prepaymentForOvernightBooking.toLocaleString()
                                }
                              )
                            ) +
                            " "
                        ),
                        _vm.playroom.prepaymentOnlyForNewGuests
                          ? _c("span", {
                              directives: [
                                {
                                  name: "t",
                                  rawName: "v-t",
                                  value:
                                    "components.PlayroomDetailPrePaymentPart.onlyForNewGuests",
                                  expression:
                                    "'components.PlayroomDetailPrePaymentPart.onlyForNewGuests'"
                                }
                              ]
                            })
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "CRow",
                [
                  _c("CCol", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value:
                          "components.PlayroomDetailPrePaymentPart.deposit",
                        expression:
                          "'components.PlayroomDetailPrePaymentPart.deposit'"
                      }
                    ],
                    attrs: { md: "6" }
                  }),
                  _vm.deposit > 0
                    ? _c("CCol", { attrs: { md: "6" } }, [
                        _vm._v(
                          " " + _vm._s(_vm.formatCurrency(_vm.deposit)) + " "
                        )
                      ])
                    : _vm._e(),
                  _vm.deposit == 0
                    ? _c("CCol", { attrs: { md: "6" } }, [
                        _c("div", {
                          directives: [
                            {
                              name: "t",
                              rawName: "v-t",
                              value:
                                "components.PlayroomDetailPrePaymentPart.noDeposit",
                              expression:
                                "'components.PlayroomDetailPrePaymentPart.noDeposit'"
                            }
                          ]
                        })
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }