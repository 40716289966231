var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "location-part" },
    [
      _c(
        "CCard",
        [
          _c("CCardHeader", [
            _c(
              "h5",
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("components.PlayroomDetailLocationPart.title")
                    ) +
                    " "
                ),
                _vm.playroom.address.hideAddress
                  ? _c("CIcon", {
                      directives: [
                        {
                          name: "c-tooltip",
                          rawName: "v-c-tooltip",
                          value: {
                            appendToBody: true,
                            content: _vm.$t(
                              "components.PlayroomDetailLocationPart.hideAddressInfo"
                            )
                          },
                          expression:
                            "{ appendToBody: true, content: $t('components.PlayroomDetailLocationPart.hideAddressInfo') }"
                        }
                      ],
                      attrs: { name: "cis-info-circle" }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _c(
            "GmapMap",
            {
              ref: "mapRef",
              staticStyle: { width: "100%", height: "300px" },
              attrs: {
                center: {
                  lat: _vm.playroom.address.latitude,
                  lng: _vm.playroom.address.longitude
                },
                zoom: 11,
                options: {
                  streetViewControl: false
                }
              }
            },
            [
              !_vm.playroom.address.hideAddress
                ? _c("GmapMarker", {
                    attrs: {
                      position: {
                        lat: _vm.playroom.address.latitude,
                        lng: _vm.playroom.address.longitude
                      }
                    }
                  })
                : _vm._e(),
              _vm.playroom.address.hideAddress
                ? _c("GmapCircle", {
                    attrs: {
                      center: {
                        lat: _vm.playroom.address.latitude,
                        lng: _vm.playroom.address.longitude
                      },
                      options: { strokeWeight: 0, fillOpacity: 0.2 },
                      radius: _vm.playroom.address.addressRadius * 1000
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }