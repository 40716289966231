var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pt10 booking-card-nav" }, [
    _c(
      "div",
      { staticClass: "edit-nav" },
      [
        _c(
          "CNav",
          { attrs: { fill: true, variant: "tabs", justified: true } },
          [
            _c(
              "CNavItem",
              {
                attrs: {
                  title: !_vm.playroom.allowOvernightStay
                    ? _vm.$t(
                        "components.BookingCard.BookingTypePart.overnightNotAvail"
                      )
                    : "",
                  active: _vm.playroom.allowHourBooking && _vm.isOvernightOn,
                  disabled: !_vm.playroom.allowOvernightStay,
                  "test-id": "overnightTab"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.showOvernight($event)
                  }
                }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("enums.bookingType.overnight")) + " "
                )
              ]
            ),
            _c(
              "CNavItem",
              {
                attrs: {
                  title: !_vm.playroom.allowHourBooking
                    ? _vm.$t(
                        "components.BookingCard.BookingTypePart.hourlyNotAvail"
                      )
                    : "",
                  active: _vm.playroom.allowHourBooking && !_vm.isOvernightOn,
                  disabled: !_vm.playroom.allowHourBooking,
                  "test-id": "hourlyTab"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.showHourly($event)
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("enums.bookingType.hourly")) + " ")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }