var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { md: "12" } },
        [
          _c(
            "CCard",
            [
              _c("CCardHeader", [
                _c("h5", {
                  directives: [
                    {
                      name: "t",
                      rawName: "v-t",
                      value: "components.PlayroomDetailHighlightsPart.title",
                      expression:
                        "'components.PlayroomDetailHighlightsPart.title'"
                    }
                  ]
                })
              ]),
              _c(
                "CListGroup",
                { attrs: { flush: true } },
                _vm._l(_vm.playroom.highlights, function(highlight, index) {
                  return _c("CListGroupItem", { key: index }, [
                    _vm._v(" " + _vm._s(highlight) + " ")
                  ])
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }