var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c(
        "CCardBody",
        [
          !_vm.order.hasDates
            ? _c("div", [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.BookingCard.BookingTypePart.overnightPricePerNight"
                        )
                      ) +
                      " "
                  )
                ]),
                _c("span", { staticClass: "text-right" }, [
                  _c("span", { staticClass: "from" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("components.BookingCard.Fromlabel")) +
                        " "
                    )
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.formatCurrency(_vm.playroom.pricePerNight)) +
                      " "
                  )
                ])
              ])
            : _vm._e(),
          _vm.order.hasDates
            ? _c("div", [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$tc(
                          "components.BookingCard.BookingTypePart.overnightTotalPrice",
                          _vm.order.totalDays,
                          { getTotalCheckInTime: _vm.order.totalDays }
                        )
                      ) +
                      " "
                  )
                ]),
                _c("span", { staticClass: "text-right" }, [
                  _vm._v(" " + _vm._s(_vm.formatCurrency(_vm.sumOfDailyPrices)))
                ])
              ])
            : _vm._e(),
          _vm.playroom.baseRentForOvernight > 0
            ? _c("div", [
                _c(
                  "span",
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.BookingCard.BookingTypePart.basePrice"
                          )
                        ) +
                        " "
                    ),
                    _c("CIcon", {
                      directives: [
                        {
                          name: "c-tooltip",
                          rawName: "v-c-tooltip",
                          value: {
                            appendToBody: true,
                            content: _vm.$t(
                              "components.PlayroomDetailPricePart.baseRent.info"
                            )
                          },
                          expression:
                            "{\n            appendToBody: true,\n            content: $t('components.PlayroomDetailPricePart.baseRent.info'),\n          }"
                        }
                      ],
                      attrs: { name: "cil-info-circle" }
                    })
                  ],
                  1
                ),
                _c("span", { staticClass: "text-right" }, [
                  _vm._v(
                    _vm._s(
                      _vm.formatCurrency(_vm.playroom.baseRentForOvernight)
                    )
                  )
                ])
              ])
            : _vm._e(),
          !_vm.order.hasDates && _vm.playroom.surchargePerPersonPerNight > 0
            ? _c("div", [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.BookingCard.BookingTypePart.overnightPersonSurcharge"
                        )
                      ) +
                      " "
                  )
                ]),
                _c("span", { staticClass: "text-right" }, [
                  _vm._v(
                    _vm._s(
                      _vm.formatCurrency(_vm.getSurchargePerPersonPerNight)
                    )
                  )
                ])
              ])
            : _vm._e(),
          _vm.order.hasDates &&
          _vm.getExtraGuests > 0 &&
          _vm.playroom.surchargePerPersonPerNight > 0
            ? _c("div", [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.BookingCard.BookingTypePart.overnightTotalSurchargePart1",
                          {
                            surcharge: _vm.formatCurrency(
                              _vm.playroom.surchargePerPersonPerNight
                            )
                          }
                        )
                      ) +
                      " " +
                      _vm._s(
                        _vm.$tc(
                          "components.BookingCard.BookingTypePart.overnightTotalSurchargePart2",
                          _vm.getExtraGuests,
                          { guestCount: _vm.getExtraGuests }
                        )
                      ) +
                      " " +
                      _vm._s(
                        _vm.$tc(
                          "components.BookingCard.BookingTypePart.overnightTotalSurchargePart3",
                          _vm.totalDays,
                          { getTotalCheckInTime: _vm.totalDays }
                        )
                      ) +
                      " "
                  )
                ]),
                _c("span", { staticClass: "text-right" }, [
                  _vm._v(
                    _vm._s(
                      _vm.formatCurrency(_vm.getSurchargePerPersonPerNight)
                    )
                  )
                ])
              ])
            : _vm._e(),
          _vm.getExtraGuests > 0 &&
          _vm.playroom.surchargePerPersonPerBookingForOvernight > 0
            ? _c("div", [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.BookingCard.BookingTypePart.bookingTotalSurcharge",
                          {
                            surcharge: _vm.formatCurrency(
                              _vm.playroom
                                .surchargePerPersonPerBookingForOvernight
                            ),
                            guestCount: _vm.getExtraGuests
                          }
                        )
                      ) +
                      " "
                  )
                ]),
                _c("span", { staticClass: "text-right" }, [
                  _vm._v(
                    _vm._s(
                      _vm.formatCurrency(_vm.getSurchargePerPersonPerBooking)
                    )
                  )
                ])
              ])
            : _vm._e(),
          _vm.bedTaxAmount > 0
            ? _c("div", [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.BookingCard.BookingTypePart.bedTax." +
                            _vm.playroom.bedTaxType,
                          { bedTax: _vm.playroom.bedTax }
                        )
                      ) +
                      " "
                  )
                ]),
                _c("span", { staticClass: "text-right" }, [
                  _vm._v(_vm._s(_vm.formatCurrency(_vm.bedTaxAmount)))
                ])
              ])
            : _vm._e(),
          _c("AdditionalOptionsSummary", {
            attrs: {
              order: _vm.order,
              additionOptions: _vm.order.additions,
              showSummaryTotal: false,
              isHourlyBooking: false
            }
          }),
          _vm.order.hasDates
            ? _c("div", [
                _c("span", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("components.BookingCard.totalAmount")))
                  ])
                ]),
                _c("span", { staticClass: "text-right totalAmount" }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.formatCurrency(+_vm.totalAmount)))
                  ])
                ])
              ])
            : _vm._e(),
          _vm.order.hasDates
            ? _c("div", [
                _c("div", [
                  _c("em", [
                    _c("small", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("components.BookingCard.conditionStar"))
                      )
                    ])
                  ])
                ]),
                _vm.showCheckInOutCondition
                  ? _c("div", [
                      _c("em", [
                        _c("small", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "components.BookingCard.conditionStarCheckInOutTime"
                                )
                              )
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }