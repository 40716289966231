var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c("CCardHeader", [
        _c("h5", {
          directives: [
            {
              name: "t",
              rawName: "v-t",
              value: "components.PlayroomDetailAreaPart.title",
              expression: "'components.PlayroomDetailAreaPart.title'"
            }
          ]
        })
      ]),
      _c(
        "CCardBody",
        [
          _c(
            "CRow",
            [
              _c("CCol", { attrs: { md: "2" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("components.PlayroomDetailAreaPart.totalArea")
                    ) +
                    " "
                )
              ]),
              _c("CCol", { attrs: { md: "10" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.formatNumber(_vm.playroom.areaInSquareMeter) + " m²"
                    ) +
                    " "
                )
              ])
            ],
            1
          ),
          _c(
            "CRow",
            [
              _c("CCol", { attrs: { md: "2" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("components.PlayroomDetailAreaPart.numberOfRooms")
                    ) +
                    " "
                )
              ]),
              _c("CCol", { attrs: { md: "10" } }, [
                _vm._v(" " + _vm._s(_vm.playroom.roomCount) + " ")
              ])
            ],
            1
          ),
          _c(
            "CRow",
            [
              _c(
                "CCol",
                { staticClass: "mt15", attrs: { md: "12" } },
                [
                  _c("h5", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value:
                          "components.PlayroomDetailAreaPart.infoOfRooms.label",
                        expression:
                          "'components.PlayroomDetailAreaPart.infoOfRooms.label'"
                      }
                    ]
                  }),
                  _c("CIcon", {
                    directives: [
                      {
                        name: "c-tooltip",
                        rawName: "v-c-tooltip",
                        value: {
                          appendToBody: true,
                          content: _vm.$t(
                            "components.PlayroomDetailAreaPart.infoOfRooms.info"
                          )
                        },
                        expression:
                          "{\n            appendToBody: true,\n            content: $t('components.PlayroomDetailAreaPart.infoOfRooms.info'),\n          }"
                      }
                    ],
                    staticClass: "icon",
                    attrs: { name: "cil-info-circle" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CRow",
            [
              _c(
                "CCol",
                { staticStyle: { "font-weight": "bold" }, attrs: { md: "4" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("components.PlayroomDetailAreaPart.name")) +
                      " "
                  )
                ]
              ),
              _c(
                "CCol",
                { staticStyle: { "font-weight": "bold" }, attrs: { md: "4" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("components.PlayroomDetailAreaPart.size")) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _vm._l(_vm.playroom.playroomRooms, function(playroomRoom, index) {
            return _c(
              "CRow",
              { key: "playroomRooms" + index },
              [
                _c("CCol", { attrs: { md: "4" } }, [
                  _vm._v(" " + _vm._s(playroomRoom.name) + " ")
                ]),
                _c("CCol", { attrs: { md: "4" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatNumber(playroomRoom.areaInSquareMeter) + " m²"
                      ) +
                      " "
                  )
                ])
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }